import {
  AppBar,
  Button,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  footer: {
    paddingRight: "79px",
    paddingLeft: "118px",
    "@media (max-width: 900px)": {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    position: "fixed",
    top: "auto",
    bottom: 0,
    width: "100%",
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
    "@media (max-width: 900px)": {
      marginLeft: "10px",
      marginBottom: "10px",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 900px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const footerData = [
  {
    label: "Contact Us",
    href: "",
  },
  {
    label: "Report an issue",
    href: "",
  },
];

export default function Footer() {
  const classes = useStyles();

  const getMenuButtons = () => {
    return footerData.map(({ label }) => {
      if (label === "Contact Us" || label === "Report an issue") {
        return (
          <Button href={`mailto:${process.env.REACT_APP_CONTACTUS_EMAIL}`}
            {...{
              key: label,
              color: "inherit",
              className: classes.menuButton
            }}
          >
            {label}
          </Button>
        );
      }
      return null;
    });
  };

  return (
    <footer>
      <AppBar className={classes.footer}>
        <Toolbar className={classes.toolbar}>
          {getMenuButtons()}
        </Toolbar>
      </AppBar>
    </footer>
  );
}