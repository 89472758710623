import React from "react";
import axios from "axios";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { getRetailerLogoForProduct } from "./shared/utils";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#3f51b5',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white',
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function Subscriptions() {
  const [subscriptions, setSubscriptions] = React.useState([]);
  const [subscriptionsMessage, setSubscriptionsMessage] = React.useState(null);
  const [subscriptionsError, setSubscriptionsError] = React.useState(null);
  const [emailId, setEmailId] = React.useState(() => {
    return localStorage.getItem("emailId");
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - subscriptions.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const subscriptionsMsgDiv = subscriptionsMessage ? (
    <Typography color="green">
      {subscriptionsMessage}
    </Typography>
  ) : '';

  const subscriptionsErrorDiv = subscriptionsError ? (
    <Typography color="error">
      {subscriptionsError}
    </Typography>
  ) : '';

  React.useEffect(() => {
    callSubscriptionApi(emailId);
  }, [emailId]);

  const [isFetching, setIsFetching] = React.useState(true);

  const callSubscriptionApi = (emailId) => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTFETCHER_URL}/api/v1/products/subscriptions`, {
        params: { emailId: emailId },
        headers: { 'authorization': `${localStorage.getItem('authToken')}` }
      })
      .then((response) => {
        setSubscriptions(response.data);
        setIsFetching(false);
      }).catch(error => {
        if (error.code === "ERR_NETWORK") {
          setSubscriptionsError("Network Error, please try after sometime");
        }
        if (error.response.data.code === 401) {
          setSubscriptionsError(error.response.data.message);
        }
        if (error.response.status === 403) {
          setSubscriptionsError(error.response.data.message);
        }
        setIsFetching(false);
      });
  }

  const onSubscriptionChange = (subscription) => {
    axios
      .post(`${process.env.REACT_APP_PRODUCTFETCHER_URL}/api/v1/products/subscribe`, {
        prodId: subscription.prodId,
        retailerName: subscription.retailerName,
        retailerCountry: subscription.retailerCountry,
        enable: subscription.enable === false ? true : false,
        desiredProductPrice: subscription.desiredPrice,
        priceAtSubscription: subscription.productPrice,
        skuId: subscription.sku,
        email: subscription.email
      }, {
        headers: { 'authorization': `${localStorage.getItem('authToken')}` }
      })
      .then((response) => {
        if (response.status === 201) {
          const newStateOfSubscriptions = subscriptions.map(obj => {
            if (obj.prodId === subscription.prodId) {
              subscription.enable = subscription.enable ? false : true;
              return { ...subscription, email: subscription.email };
            } else {
              return obj;
            }
          });
          setSubscriptions(newStateOfSubscriptions);
        }
      });
  };

  const onDeleteSubscriptionRow = (subscription) => {
    let catalogueId = subscription.catalogueId;
    let email = subscription.email;
    axios
      .delete(`${process.env.REACT_APP_PRODUCTFETCHER_URL}/api/v1/products/subscription/${email}/delete/${catalogueId}`, {
        headers: { 'authorization': `${localStorage.getItem('authToken')}` }
      })
      .then((response) => {
        if (response.status === 200) {
          const newStateOfSubscriptions = subscriptions.filter(sub => sub.prodId !== subscription.prodId)
          setSubscriptions(newStateOfSubscriptions);
        }
      }).catch(error => {
        console.log("Error: " + error.response.data.message);
      });
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} md={10} lg={8}>
        {subscriptionsMsgDiv}
        {subscriptionsErrorDiv}
        {subscriptions && subscriptions.length > 0 ? (
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Retailer</StyledTableCell>
                  <StyledTableCell align="center">Product Image</StyledTableCell>
                  <StyledTableCell align="center">Product</StyledTableCell>
                  <StyledTableCell align="center">Subscription Enabled?</StyledTableCell>
                  <StyledTableCell align="center">Subscribed Price</StyledTableCell>
                  <StyledTableCell align="center">Current Price</StyledTableCell>
                  <StyledTableCell align="center">Desired Price</StyledTableCell>
                  <StyledTableCell align="center">Last Updated</StyledTableCell>
                  <StyledTableCell align="center">Delete</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? subscriptions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : subscriptions
                ).map((subscription) => (
                  <StyledTableRow key={`${subscription.catalogueId}-${subscription.prodId}`} id={`${subscription.catalogueId}-${subscription.prodId}`}>
                    <StyledTableCell align="center">{getRetailerLogoForProduct(subscription.retailerName)}</StyledTableCell>
                    <StyledTableCell align="center"><img src={subscription.prodImg} alt="" style={{ width: 75, height: 75 }} /></StyledTableCell>
                    <StyledTableCell align="center"><a href={subscription.productUrl} rel="noreferrer" style={{ color: "black" }} target="_blank">{subscription.prodDescription}</a></StyledTableCell>
                    <StyledTableCell align="center">
                      <Button variant={subscription.enable ? "contained" : "outlined"} onClick={() => onSubscriptionChange(subscription)}>
                        {subscription.enable ? "Enabled" : "Disabled"}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell align="center">{subscription.priceAtSubscription}</StyledTableCell>
                    <StyledTableCell align="center">
                      {subscription.currentPrice <= subscription.desiredPrice ? (
                        <span>
                          <img style={{ width: 20, height: 20 }} src='../../images/alert.png' title='Price drop alert' />
                          {subscription.currentPrice}
                        </span>
                      ) : (
                        <span>{subscription.currentPrice}</span>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">{subscription.desiredPrice}</StyledTableCell>
                    <StyledTableCell align="center">{subscription.lastFetchedTs}</StyledTableCell>
                    <StyledTableCell align="center">
                      <IconButton style={{ color: "#3366ff" }} aria-label="delete" onClick={() => onDeleteSubscriptionRow(subscription)}>
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={9} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={9}
                    count={subscriptions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            {isFetching ? <CircularProgress /> : <Typography>No subscriptions found.</Typography>}
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default Subscriptions;