import React from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { TextField, Grid, Typography, Container, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Linechart from './shared/linechart';
import { getRetailerLogoForProduct } from "./shared/utils";

const useStyles = makeStyles(() => ({
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
    marginBottom: '20px',
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  chart: {
    width: '100%',
    maxWidth: '600px',
    height: '300px',
  },
  textField: {
    minWidth: '250px',
    maxWidth: '100%',
  },
  centeredContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '5px',
    paddingRight: '10px',
    paddingBottom: '10px',
    paddingLeft: '10px',
    width: '100%'
  },
  tableContainer: {
    maxHeight: '400px',
    overflowY: 'auto'
    
  },
}));

function ProductSearch(props) {
  const [product, setProduct] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [subscriptionMessage, setSubscriptionMessage] = React.useState(null);
  const [subscriptionError, setSubscriptionError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const errorDiv = error ? (
    <Typography color="error" style={{ paddingTop: '10px' }}>
      {error}
    </Typography>
  ) : '';

  const subscriptionMsgDiv = subscriptionMessage ? (
    <Typography color="primary" style={{ paddingTop: '10px' }}>
      {subscriptionMessage}
    </Typography>
  ) : '';

  const subscriptionErrorDiv = subscriptionError ? (
    <Typography color="error" style={{ paddingTop: '10px' }}>
      {subscriptionError}
    </Typography>
  ) : '';

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: { url: "" } });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 }
  } = useForm({ defaultValues: { desiredPrice: "" } });

  const inputProps = {
    step: 300,
    size: 100,
  };

  /***Chart config starts */
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  const [priceHistory, setPriceHistory] = React.useState([]);
  const [chartData, setChartData] = React.useState({
    datasets: [
      {
        label: 'price',
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  });
  /***Chart config Ends */

  const { menuButton, buttonContainer, chartContainer, chart, textField, centeredContainer, tableContainer } = useStyles();

  const onProdSearchSubmit = (formData) => {
    setError(null);
    setSubscriptionError(null);
    setSubscriptionMessage(null);
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_PRODUCTFETCHER_URL}/api/v1/products/urlsearch`, {
        params: {
          productUrl: formData.url,
          skipDB: true
        },
        headers: {
          'authorization': `${localStorage.getItem('authToken')}`
        }
      })
      .then((response) => {
        setProduct(response.data);
        const priceHis = createChartData(response.data.priceHistory);
        setPriceHistory(priceHis);
        setChartData({
          datasets: [
            {
              label: 'price',
              data: priceHis.map((priceHistoryObj) => ({ x: priceHistoryObj.x, y: priceHistoryObj.y })),
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
          ]
        });
        setLoading(false);
      }).catch(error => {
        setProduct([]);
        if (error.response.status === 500) {
          setError("Please try after sometime, if continues, report the issue");
        } else {
          setError(error.response.data.message);
        }
        setLoading(false);
      });
  };

  function createChartData(priceHistory) {
    return priceHistory.map(({ price, creationDate }) => ({
      x: creationDate.substring(0, 10),
      y: price
    }));
  }

  const onSubscribeSubmit = (formData) => {
    setSubscriptionError(null);
    if (formData.desiredPrice === undefined || formData.desiredPrice <= 0) {
      setSubscriptionError("Subscription price can't be null/0 or negative");
      return;
    }
    axios
      .post(`${process.env.REACT_APP_PRODUCTFETCHER_URL}/api/v1/products/subscribe`, {
        prodId: product.prodId,
        retailerName: product.retailerName,
        retailerCountry: product.retailerCountry,
        enable: true,
        desiredProductPrice: formData.desiredPrice,
        priceAtSubscription: product.currentPrice,
        skuId: product.sku,
        email: localStorage.getItem("emailId"),
      }, {
        headers: {
          'authorization': `${localStorage.getItem('authToken')}`
        }
      })
      .then((response) => {
        setSubscriptionMessage(response.data);
      }).catch(error => {
        if (error.response.status === 500) {
          setSubscriptionError("Please try after sometime");
        } else if (error.response.status === 403) {
          setSubscriptionError("Please login to subscribe");
        } else {
          setSubscriptionError(error.response.data.message);
        }
      });
  };

  return (
    <Container maxWidth="md" className={centeredContainer}>
      <Typography variant="h4" component="h1" gutterBottom style={{ color: '#3f51b5', fontFamily: "Open Sans, sans-serif", fontWeight: 600 }}>
        Product Search
      </Typography>
      <form key={"productUrlSearchForm"} onSubmit={handleSubmit(onProdSearchSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="url"
              name="url"
              type="text"
              inputProps={inputProps}
              label="Enter URL of Product"
              variant="standard"
              fullWidth
              {...register("url", { required: true })}
            />
            {errors.url && errors.url.type === "required" && (
              <Typography color="error" className="errorMsg">*Url is required.</Typography>
            )}
            {errorDiv}
          </Grid>
          <Grid item xs={12} className={buttonContainer}>
            <LoadingButton
              type="submit"
              size="small"
              loading={loading}
              variant="contained"
            >
              Search
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
      {!(Object.keys(product).length === 0) && (
        <div>
          <TableContainer component={Paper} className={tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow style={{ backgroundColor: "#3f51b5", color: "white" }}>
                  <TableCell>Retailer</TableCell>
                  <TableCell>Product Image</TableCell>
                  <TableCell>Product</TableCell>
                  {product.regularPrice !== undefined && <TableCell>Regular Price</TableCell>}
                  <TableCell>Current Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={product.prodId}>
                  <TableCell>{getRetailerLogoForProduct(product.retailerName)}</TableCell>
                  <TableCell>
                    <img src={product.productImg} alt="" style={{ width: 75, height: 75 }} />
                  </TableCell>
                  <TableCell>
                    <a href={product.productUrl} rel="noreferrer" style={{ color: "black" }} target="_blank">{product.productDescription}</a>
                  </TableCell>
                  {product.regularPrice !== undefined && (
                    <TableCell style={{ color: "black" }}>{product.regularPrice}</TableCell>
                  )}
                  <TableCell>{product.currentPrice}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
         
          <form key={"subscriptionForm"} onSubmit={handleSubmit2(onSubscribeSubmit)}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="desiredPrice"
                  name="desiredPrice"
                  type="text"
                  inputProps={{ inputMode: 'numeric', pattern: '-?[0-9]*(\.[0-9]+)?' }}
                  label="Enter desired price for alerts"
                  variant="standard"
                  className={textField}
                  {...register2("desiredPrice", { required: true })}
                />
                {errors2.desiredPrice && errors2.desiredPrice.type === "required" && (
                  <Typography color="error" className="errorMsg">*Price is required to subscribe.</Typography>
                )}
                {subscriptionMsgDiv}
                {subscriptionErrorDiv}
              </Grid>
              <Grid item xs={12} className={buttonContainer}>
                <Button type="submit" variant="contained">
                  Subscribe
                </Button>
              </Grid>
            </Grid>
          </form>
          <Box className={chartContainer}>
            <div className={chart}>
              <Linechart chartData={chartData} />
            </div>
          </Box>
        </div>
      )}
    </Container>
  );
}

export default ProductSearch;