import React, { useState } from "react";
import "../About.css";

function About() {
  const FAQ = ({ faq, index, toggleFAQ }) => {
    return (
      <div
        className={"faq " + (faq.open ? "open" : "")}
        key={index}
        onClick={() => toggleFAQ(index)}
      >
        <div className="faq-question">{faq.question}</div>
        <div className="faq-answer">{faq.answer}</div>
      </div>
    );
  };

  const [faqs, setFaqs] = useState([
    {
      question: "What is dealprice.ca ?",
      answer:
        "Dealprice.ca is an application that tracks the prices of products from different retailers present online.",
      open: true
    },
    {
      question: "How dealprice.ca works ?",
      answer: "User needs to copy paste the url of product on 'search' page. After clicking search, it will fetch the current price of product. Then user can subscribe for alerts based on what price you are looking for. To track the price, user needs to register/login via google login, so that the alerts can be sent over the email.",
      open: false
    },
    {
      question: "How can I manage my subscriptions ?",
      answer: "Whatever products you have subscribed for, can be seen on 'subscriptions' page. From there, you can enable, disable or delete the subscription.",
      open: false
    },
    {
      question: "How soon we get the price drop alert ?",
      answer: "Product prices are fetched few times a day and an email alert is sent soon after that.",
      open: false
    },
    {
      question: "What all retailers we can search for ?",
      answer: "We are working on adding more retailers. Currently supported retailers are the Images that are rotating on every page.",
      open: false
    }
  ]);

  const toggleFAQ = index => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
        return faq;
      })
    );
  };

  return (
    <div>
      <label style={{color:'#3f51b5',fontSize:'30px',fontFamily: "Open Sans, sans-serif",fontWeight: 700}}>FAQs</label>
      <div className="faqs">
        {faqs.map((faq, index) => (
          <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </div>
  );
}

export default About;